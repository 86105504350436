@page{
    size : auto;
    margin: 0mm;
}
div ul.boutonFacture{
    float: right;
}
div li button{
    width: 10em;
}
tr td.tableVide{
    border-left: 0 ;
}
.table{
    border-top: 1px solid black;
    border-right: 1px solid;
}
thead.entete{
    border-bottom: 1px solid;
}
thead th.bdroit{
    border-right: 1px solid;
}
thead th{
    text-align: center;
}
thead th.qte{
    border-left: 1px solid;
}
tbody.facture tr td{
    border: 1px solid black;
    border-bottom: none;
    border-top: none;
}
tbody.facture tr.tableVide:last-child td {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    border-left: none;
}
tbody.facture tr:last-child td:first-child, tbody.facture tr:last-child td:nth-child(1){
    border-bottom: none;
}

tr td.dernierTd{
    text-align: center;
}
td.total{
    text-align: center;
}
@media(max-width : 1200px){
    p.prixTexte{
        font-size: 12px;
    }
}
