div.bChoix{
    background-color: rgba(217, 83, 79, 0.2);
    color: #cc2334;
}
div.bChoix:hover{
    background-color: #d62638;
    color: white;
}
p.modalTitre{
    font-size: 18px;
    font-weight: 500;
}