@media(max-width : 2045px){
    .tableLocation{
        overflow-x: scroll;
    }
}
button.bDetail{
    font-size:16px;
}
button.bDetail:hover{
    color: rgb(207, 7, 7);
}