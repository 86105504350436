tr.total td:last-child{ 
    border: 1px solid;
    text-align: center;
    padding: 10px 0px 15px 0px;
}
.sommePaie{
    font-size : 22px;
}
@media(max-width : 1600px){
    .sommePaie{
        font-size: 17px;
    }
}
@media(max-width : 2045px){
    .tablePaiement{
        overflow-x: scroll;
    }
}